import { siteConfig } from "../constants/Constants";

export function findColorName(array: any, idToFind: any) {
  const obj =
    array && array?.find((item: any) => Number(item.id) === Number(idToFind));
  return obj?.name || "";
}

export const loadScript = (src: any) => {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
};

export const sizes = ["s", "m", "l", "xl"];

export const qrPrices = [
  590, 690, 790, 890, 990, 1490, 1990, 1380, 1580, 1780, 1290,
];
// amount_590.jpg

export const createUrl = (product_name: string) => {
  return product_name ? product_name?.split(" ").join("-") : "";
};

export const getDiscountInPercentage = (product: any) => {
  let per = (product?.mrp - product?.discounted_price) / product?.mrp;
  return (per * 100).toFixed(0);
};

export function calculateGST(amount: number) {
  const gstPercentage = 18; // GST percentage
  const gstAmount = (amount * gstPercentage) / 100;
  return gstAmount;
}

export function generateDateArrayWithMonthAndDayNames() {
  const datesArray = [];
  const currentDate = new Date();
  const monthNamesLong = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const monthNamesShort = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const dayNamesShort = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const dayNamesLong = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  for (let i = 0; i < 6; i++) {
    const futureDate = new Date(currentDate);
    futureDate.setDate(currentDate.getDate() + i);

    let flag = "";
    if (i === 0) {
      flag = "Today";
    } else if (i === 1) {
      flag = "Tomorrow";
    }

    // Getting month and day names
    const monthIndex = futureDate.getMonth(); // getMonth() returns 0-11
    const monthLong = monthNamesLong[monthIndex];
    const monthShort = monthNamesShort[monthIndex];
    const dayShort = dayNamesShort[futureDate.getDay()]; // getDay() returns 0-6
    const dayLong = dayNamesLong[futureDate.getDay()]; // getDay() returns 0-6

    datesArray.push({
      date: futureDate.toISOString().split("T")[0], // Format date as YYYY-MM-DD
      monthLong: monthLong, // Long month name
      monthShort: monthShort, // Short month name
      dayShort: dayShort, // Short day name
      flag: flag,
      dayLong,
    });
  }

  return datesArray;
}

export function transformDate(customDate: any) {
  // Mapping month names to their numerical values
  const months: any = {
    January: 1,
    February: 2,
    March: 3,
    April: 4,
    May: 5,
    June: 6,
    July: 7,
    August: 8,
    September: 9,
    October: 10,
    November: 11,
    December: 12,
  };

  // Extracting the numerical month value
  const monthNumber = months[customDate?.month];

  // Constructing the new date string
  const newDateStr = `${customDate.year}-${String(monthNumber).padStart(
    2,
    "0"
  )}-${String(customDate.day).padStart(2, "0")}T00:00:00.000Z`;

  return newDateStr;
}

export function setDateTimeWithToday(hourMinutePeriod: any) {
  // Getting today's date
  const today = new Date();

  // Extracting hour, minute, and period from the input
  let { hour, minute, period } = hourMinutePeriod;

  // Converting 12-hour format to 24-hour format
  if (period === "PM" && hour !== "12") {
    hour = parseInt(hour, 10) + 12;
  } else if (period === "AM" && hour === "12") {
    hour = "00";
  }

  // Setting the hours and minutes
  today.setHours(hour, minute, 0, 0); // Sets hours, minutes, seconds, and milliseconds

  // Formatting the date as an ISO string and slicing to remove milliseconds and Z
  const formattedDate = today.toISOString().slice(0, -5) + "Z";

  return formattedDate;
}

export function formatDate(dateStr: any) {
  const date = new Date(dateStr);
  const day = date.getDate();
  const month = date.toLocaleString("default", { month: "long" });

  let daySuffix;
  if (day % 10 === 1 && day !== 11) {
    daySuffix = "st";
  } else if (day % 10 === 2 && day !== 12) {
    daySuffix = "nd";
  } else if (day % 10 === 3 && day !== 13) {
    daySuffix = "rd";
  } else {
    daySuffix = "th";
  }

  return `${day}${daySuffix} ${month}`;
}

export const getCurrency = (currency: any) => {
  if (!currency) {
    return "";
  }
  if (currency === "INR") {
    return siteConfig.global.currency + " ";
  }
  return currency + " ";
};

export const countryCodes = [
  { code: "+91", country: "IN", currency: "INR", symbol: "₹" },
  { code: "+1", country: "USA", currency: "USD", symbol: "USD" },
  // { code: "+44", country: "USA", currency: "GBP", symbol: "£" },
  // { code: "+65", country: "USA", currency: "SGD", symbol: "SGD" },
];

export function capitalizeFirstLetter(string:any) {
  if(!string) return ""
  return string?.charAt(0).toUpperCase() + string?.slice(1);
}

// Function to store UTM parameters from the URL into localStorage
export function storeUTMParams(): void {
  const urlParams = new URLSearchParams(window.location.search);
  const utmParams: Record<string, string> = {};

  console.log(urlParams,"urlParamsurlParamsurlParams");
  
  // Iterate through all parameters in the URL
  urlParams.forEach((value, key) => {
    if (key.startsWith("utm_")) {
      utmParams[key] = value; // Add UTM params to the object
    }
  });

  // Store the UTM params object in localStorage with key 'utm'
  if (Object.keys(utmParams).length > 0) {
    localStorage.setItem("utm", JSON.stringify(utmParams));
  }
}

// Function to retrieve UTM parameters from localStorage
export function getUTMParams(): Record<string, string> | null {
  const storedUTM = localStorage.getItem("utm");
  if (storedUTM) {
    try {
      return JSON.parse(storedUTM) as Record<string, string>;
    } catch (error) {
      console.error("Error parsing UTM parameters from localStorage:", error);
      return null;
    }
  }
  return null;
}