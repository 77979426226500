import { Select } from "antd";
import React, { useEffect, useState } from "react";

const CustomDatePicker = ({ onChange, className, value }: any) => {
  // Initialize state for the day, month, and year
  const [day, setDay] = useState(value?.day || "");
  const [month, setMonth] = useState(value?.month || "");
  const [year, setYear] = useState(value?.year || "");

  // Generate arrays for days, months, and years
  const days = Array.from({ length: 31 }, (_, i) => i + 1);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const currentYear = new Date().getFullYear();
  const years = Array.from(
    { length: currentYear - 1940 + 1 },
    (_, i) => i + 1940
  );

  // Handle changing the date
  const handleDateChange = (value: any, type: string) => {
    if (type === "day") setDay(value);
    else if (type === "month") setMonth(value);
    else if (type === "year") setYear(value);

    // Trigger onChange with updated date
    if (onChange)
      onChange({
        day: type === "day" ? value : day,
        month: type === "month" ? value : month,
        year: type === "year" ? value : year,
      });
  };

  // Sync the default value with the state
  useEffect(() => {
    if (value) {
      setDay(value.day || "");
      setMonth(value.month || "");
      setYear(value.year || "");
    }
  }, [value]);

  return (
    <div className={className}>
      {/* Day Selector */}
      <Select
        className="w-[100px] mr-2"
        placeholder="Day"
        value={day || undefined}
        onChange={(value) => handleDateChange(value, "day")}
        options={days.map((d) => ({
          value: d,
          label: d,
        }))}
      />

      {/* Month Selector */}
      <Select
        className="w-[120px] mr-2"
        placeholder="Month"
        value={month || undefined}
        onChange={(value) => handleDateChange(value, "month")}
        options={months.map((m) => ({
          value: m, // Send the string value for months
          label: m,
        }))}
      />

      {/* Year Selector */}
      <Select
        className="w-[100px] mr-2"
        placeholder="Year"
        value={year || undefined}
        onChange={(value) => handleDateChange(value, "year")}
        options={years.reverse().map((y) => ({
          value: y,
          label: y,
        }))}
      />
    </div>
  );
};

export default CustomDatePicker;